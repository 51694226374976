import React, { useState, createContext } from 'react'

const ThemeContext = createContext(null)

const Theme = ({ children }) => {
	const windowGlobal = typeof window !== 'undefined' && window
	var light
	if (windowGlobal) {
		light = JSON.parse(windowGlobal.localStorage.getItem('light'))
	}
	const [theme, setTheme] = useState(
		light === false && light != null ? false : true
	)
	const [open, setOpen] = useState(false)

	const [mTheme, setMTheme] = useState(false)

	return (
		<ThemeContext.Provider
			value={{ theme, setTheme, open, setOpen, mTheme, setMTheme }}
		>
			{children}
		</ThemeContext.Provider>
	)
}
export { Theme }

export default ThemeContext
