import React, { useState, createContext } from 'react'

const HoverContext = createContext(null)

const Hover = ({ children }) => {
	const [cursorStyle, setCursorStyle] = useState({
		height: false,
		width: false,
		transition: false,
		text: false,
	})

	const [cursorPos, setCursorPos] = useState({
		x: false,
		y: false,
		isLeft: false,
	})

	return (
		<HoverContext.Provider
			value={{ cursorStyle, setCursorStyle, cursorPos, setCursorPos }}
		>
			{children}
		</HoverContext.Provider>
	)
}

export { Hover }
export default HoverContext
