import React from 'react'
import { Theme } from './src/context/ThemeContext'
import { Hover } from './src/context/HoverContext'

export const onInitialClientRender = () => {
	setTimeout(function () {
		document.getElementById('___loader').style.display = 'none'
	}, 1000)
}

export const wrapRootElement = ({ element }) => {
	// if (process.env.NODE_ENV !== 'production') {
	// 	const whyDidYouRender = require('@welldone-software/why-did-you-render')
	// 	whyDidYouRender(React, { hotReloadBufferMs: 1500 })
	// }
	return (
		<Theme>
			<Hover>{element}</Hover>
		</Theme>
	)
}

export const onServiceWorkerUpdateReady = () => {
	const answer = window.confirm(
		`Website has been updated. ` + `Reload to display the latest version?`
	)

	if (answer === true) {
		window.location.reload()
	}
}
